import { Autocomplete, DialogContent, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { InfoBox } from "../../Layout/InfoBox/InfoBox";
import { formatDate } from "../../../utils/helpers";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    justifyContent: "space-between",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  intro: {
    marginBottom: "10px",
  },
  enum: {
    "&:not(:last-child):after": {
      content: '", "',
    },
  },
}));

export default function ConfigSummary({
  handlePrevious,
  handleSave,
  config,
  loading,
}) {
  const classes = useStyles();
  const [workedDays, setWorkedDays] = useState([]);
  const { t, i18n } = useTranslation("myAccount");

  const locale = i18n.language;

  const daysOfWeek = [
    { id: 0, label: t("Monday", { ns: "common" }) },
    { id: 1, label: t("Tuesday", { ns: "common" }) },
    { id: 2, label: t("Wednesday", { ns: "common" }) },
    { id: 3, label: t("Thursday", { ns: "common" }) },
    { id: 4, label: t("Friday", { ns: "common" }) },
    { id: 5, label: t("Saturday", { ns: "common" }) },
    { id: 6, label: t("Sunday", { ns: "common" }) },
  ];

  const formatAutocompleteDaysList = (dayIds) => {
    return dayIds.map((id) =>
      daysOfWeek.find((dayOfWeek) => dayOfWeek.id === id)
    );
  };

  useEffect(() => {
    const orderedWorkedDays = [...config.workingTime.workedDays];
    orderedWorkedDays.sort((a, b) => a - b);
    const formattedWorkedDays = formatAutocompleteDaysList(orderedWorkedDays);
    setWorkedDays(formattedWorkedDays);
  }, [config]);

  return (
    <>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.summary.1")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography type="h5" variant="h5" marginBottom={1}>
              {t("fiscal_year")}
            </Typography>
            <Typography type="p" variant="body1">
              {` ${t("From")} `}
              {formatDate(config.fiscalYear.dateFrom, locale)} {` ${t("to")} `}
              {formatDate(config.fiscalYear.dateTo, locale)}
            </Typography>
            <Typography variant="body1" marginTop={1}>
              {t("fiscal_years.week_starts_on.title")}
              {t(
                `fiscal_years.week_starts_on.${config.fiscalYear.weekFirstDay}`
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography type="h5" variant="h5" marginBottom={1}>
              {t("config_assistant.time_off.sickness")}{" "}
            </Typography>
            <Typography type="p" variant="body1">
              {config.timeOff.sickValue} {t(`rules.${config.timeOff.unit}s`)}{" "}
              {t("rules.by")} {t(`rules.${config.timeOff.period}`)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography type="h5" variant="h5" marginBottom={1}>
              {t("config_assistant.time_off.vacation")}{" "}
            </Typography>
            <Typography type="p" variant="body1">
              {config.timeOff.vacationValue}{" "}
              {t(`rules.${config.timeOff.unit}s`)} {t("rules.by")}{" "}
              {t(`rules.${config.timeOff.period}`)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography type="h5" variant="h5" marginBottom={1}>
              {t("config_assistant.working_time.title")}{" "}
            </Typography>
            <Typography type="p" variant="body1">
              {config.workingTime.hours} {t(`rules.hours`)} {t("rules.by")}{" "}
              {t(`rules.week`)}
              {" : "}
              {workedDays.map((day) => (
                <span key={day.id} className={classes.enum}>
                  {day.label}
                </span>
              ))}
            </Typography>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <Typography type="h5" variant="h5" marginBottom={1}>*/}
          {/*    {t("config_assistant.public_holidays.title")}{" "}*/}
          {/*  </Typography>*/}
          {/*  <Typography type="p" variant="body1">*/}
          {/*    {config.publicHolidays.dates.map((date) => (*/}
          {/*      <span key={date} className={classes.enum}>*/}
          {/*        {formatDate(date, locale)}*/}
          {/*      </span>*/}
          {/*    ))}*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
        </Grid>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          component="label"
          color="darkSecondary"
          onClick={handlePrevious}
        >
          {t("config_assistant.previous")}
        </Button>
        <LoadingButton
          variant="contained"
          component="label"
          color="primary"
          onClick={handleSave}
          loading={loading}
        >
          {t("config_assistant.save")}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
