import { DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { InfoBox } from "../../Layout/InfoBox/InfoBox";
import DialogActions from "@mui/material/DialogActions";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { BOOK_DEMO_URL } from "../../../config/config";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    justifyContent: "right",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  intro: {
    marginBottom: theme.spacing(2),
  },
  note: {
    marginTop: theme.spacing(2),
  },
}));

export default function Intro({ handleNext }) {
  const classes = useStyles();
  const { t } = useTranslation("myAccount");

  const handleClickNext = () => {
    handleNext();
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.intro.1")}
            </Typography>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.intro.2")}
            </Typography>
            <Typography type="p" variant="body1" className={classes.intro}>
              {t("config_assistant.intro.3")}
            </Typography>
            <InfoBox level={"info"}>
              {t("config_assistant.requirements.title")}
              <ul>
                <li>{t("config_assistant.requirements.1")}</li>
                <li>{t("config_assistant.requirements.2")}</li>
                {/*<li>{t("config_assistant.requirements.3")}</li>*/}
              </ul>
            </InfoBox>
            <Typography type="p" variant="body1" className={classes.note}>
              {t("config_assistant.intro.note")}
              <a href={BOOK_DEMO_URL}>
                {t("config_assistant.intro.make_appointement")}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          component="label"
          color="primary"
          onClick={handleClickNext}
        >
          {t("config_assistant.start")}
        </Button>
      </DialogActions>
    </>
  );
}
